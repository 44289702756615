<template>
  <div class="container">
    <content-title :nav="nav"></content-title>

    <div class="contentList">
      <!-- <h4 class="sec-title">合作商管理</h4> -->

      <el-form :inline="true" class="demo-form-inline">
        <el-form-item label="子类目">
          <el-select
            v-model="categories_child"
            clearable
            name="categories_child"
            id="categories_child"
            remote
            @focus.once="GetListPageChild"
          >
            <el-option
              v-for="item in listPageChild"
              :key="item.id"
              :label="item.name"
              :value="item.name"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="机构名称">
          <el-input
            type="text"
            clearable
            v-model="mechanism_name"
            name="mechanism_name"
            id="name"
          />
        </el-form-item>
        <el-form-item label="联系人电话">
          <el-input
            type="text"
            clearable
            v-model="contact_telephone"
            name="mechanism_telephone"
            id="tell"
          />
        </el-form-item>
        <el-form-item label="状态">
          <el-select v-model="status">
            <el-option label="已通过" :value="2"></el-option>
            <el-option label="待审核" :value="1"></el-option>
            <el-option label="审核未通过" :value="3"></el-option>
            <el-option label="注销" :value="4"></el-option>
            <el-option label="已冻结" :value="5"></el-option>
            <el-option label="预留待打电话" :value="6"></el-option>
            <el-option label="预留已联系" :value="7"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button @click="search()" type="primary">查询</el-button>
        </el-form-item>
      </el-form>

      <div class="paging">
        <el-table
          :header-cell-style="{ background: '#EAEAEA', color: '#434343' }"
          :data="recording"
          tooltip-effect="dark"
          style="width: 100%"
          @filter-change="filterChange"
          highlight-current-row
          v-loading="is_loading"
        >
          <el-table-column
            prop="id"
            align="center"
            label="id"
          ></el-table-column>
          <el-table-column
            prop="mechanism_name"
            align="center"
            label="机构名称"
          ></el-table-column>
          <!-- <el-table-column
            prop="type"
            align="center"
            label="机构类型"
          ></el-table-column> -->
          <el-table-column
            prop="categories_child"
            align="center"
            label="门店类别"
          ></el-table-column>
          <el-table-column
            prop="categories"
            align="center"
            label="门店大类别"
          ></el-table-column>
          <el-table-column
            :formatter="fromTitle"
            prop="payee_logon_id"
            align="center"
            label="支付宝账号"
          >
          </el-table-column>
          <el-table-column
            prop="mechanism_addr"
            align="center"
            label="机构地址"
          >  <template slot-scope="scope">
        <el-popover trigger="hover" placement="top">
          <p>地址: {{ scope.row.mechanism_addr }}</p>
          <div slot="reference" class="name-wrapper">
            <el-tag size="medium">机构地址</el-tag>
          </div>
        </el-popover>
      </template>
      </el-table-column>
          <el-table-column
            :formatter="fromTitle"
            prop="contacts"
            align="center"
            label="负责人姓名"
          ></el-table-column>
          <el-table-column
            :formatter="fromTitle"
            prop="contact_telephone"
            align="center"
            label="负责人电话"
          >
          </el-table-column>
          <!-- <el-table-column
            prop="contact_telephone"
            align="center"
            label="主账号"
          ></el-table-column> -->
          <!-- <el-table-column
            prop="map.mechanism_name"
            align="center"
            label="关联机构"
          ></el-table-column> -->
          <el-table-column
            prop="update_time"
            align="center"
            label="申请时间"
            width="200px"
          ></el-table-column>
          <el-table-column prop="update_time" align="center" label="是否推荐">
            <template slot-scope="scope">
              {{ scope.row.is_recommend == 1 ? "是" : "否" }}
            </template>
          </el-table-column>
          <el-table-column prop="status" label="状态" align="center">
            <template slot-scope="scope">
              <span v-if="scope.row.status == 1" style="color: #23c3c4"
                >待审核</span
              >
              <span v-else-if="scope.row.status == 2" style="color: #b8b8b8"
                >已通过</span
              >
              <span v-else-if="scope.row.status == 3" style="color: #ff6262"
                >已拒绝</span
              >
              <span v-else-if="scope.row.status == 4" style="color: #ff6262"
                >注销</span
              >
              <span v-else-if="scope.row.status == 5" style="color: #ff6262"
                >冻结</span
              >
              <span v-else-if="scope.row.status == 6" style="color: #ff6262"
                >待联系</span
              >
              <span v-else-if="scope.row.status == 7" style="color: #b8b8b8"
                >已联系</span
              >
            </template>
          </el-table-column>
          <el-table-column
            prop="is_recommend"
            align="left"
            label="操作"
            width="300"
          >
            <template slot-scope="scope">
              <div style="display: flex; flex-wrap: wrap">
                <el-button
                  @click="toDetails(scope.row)"
                  size="small"
                  type="primary"
                  >查看详情</el-button
                >
                <!-- <el-button
                  @click="handleCurrentChangeto(scope.row)"
                  type="text"
                  size="small"
                  >选择机构</el-button
                > -->
                <el-button
                  @click="openUpdate(scope.row)"
                  size="small"
                  type="success"
                  >编辑
                </el-button>
                <el-button
                  @click="openBind(scope.row)"
                  size="small"
                  type="warning"
                  v-if="!scope.row.contact_telephone"
                >
                  绑定负责人
                </el-button>
                <el-button
                  @click="openBindMec(scope.row)"
                  size="small"
                  type="warning"
                >
                  绑定主/分店
                </el-button>

                <el-button
                  @click="frozen(scope.row)"
                  size="small"
                  v-if="scope.row.status == 2"
                  type="danger"
                  >冻结
                </el-button>
                <el-button
                  @click="unfrozen(scope.row)"
                  size="small"
                  v-if="scope.row.status == 5"
                  type="warning"
                  >解冻
                </el-button>
                <el-button
                  @click="tuijian(scope.row)"
                  size="small"
                  v-if="scope.row.is_recommend == 1 && scope.row.status == 2"
                  type="warning"
                  >取消推荐</el-button
                >
                <el-button
                  @click="tuijian(scope.row)"
                  size="small"
                  v-if="scope.row.is_recommend != 1 && scope.row.status == 2"
                  type="success"
                  >推荐</el-button
                >
                <el-button
                  @click="contacted(scope.row)"
                  size="small"
                  v-if="scope.row.status == 6"
                  type="success"
                  >已联系
                </el-button>
              </div>
            </template>
          </el-table-column>
        </el-table>
        <!--分页-->
        <paging-fy
          @currentPageChange="handleCurrentChange"
          :currentPage="currentPage"
          :total="total"
        ></paging-fy>
      </div>
    </div>
    <el-dialog
      :visible="bindVisible"
      title="绑定用户"
      :before-close="handleClose"
    >
      <label for="">选择用户</label>
      <el-select
        remote
        :remote-method="getUserList"
        v-model="user_id"
        filterable
        placeholder="请输入查询"
      >
        <el-option
          v-for="item in user_list"
          :key="item.user_id"
          :value="item.user_id"
          :label="item.nick_name"
        >
        </el-option>
      </el-select>
      <span slot="footer">
        <el-button @click="bindUser()">确认</el-button>
        <el-button @click="handleClose">取消</el-button>
      </span>
    </el-dialog>
    <el-dialog
      :visible="bindMecVisible"
      title="绑定主/分店"
      :before-close="handleClose"
    >
      <div>
        <div>
          <label for="主店"></label>
          <div>{{ temp.mechanism_name }}</div>
          <div>
            <label for="分店"></label>
            <div>
              <div v-for="part in part_list" :key="part.id">
                <span>{{ part.binding_mechanism_id }}</span>
                <el-button @click="upgradeToMain(part, part_list)"
                  >提升为主店</el-button
                ><!-- todo -->
                <el-button @click="moveTo(part)">></el-button>
              </div>
            </div>
          </div>
        </div>
        <div v-for="item in main_list" :key="item.id">
          <label for="主店"></label>
          <div>{{ item.mechanism_name }}</div>
          <div>
            <label for="分店"></label>
            <div>
              <div v-for="part in main_list.part_list" :key="part.id">
                <span>{{ part.binding_mechanism_id }}</span>
                <el-button @click="upgradeToMain(part, main_list.part_list)"
                  >提升为主店</el-button
                >
                <el-button>></el-button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <span slot="footer">
        <el-button @click="bindUser()">确认</el-button>
        <el-button @click="handleClose">取消</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import newMec from "../../components/newMec.vue";
export default {
  components: { newMec },
  data() {
    return {
      nav: {
        firstNav: "合作商理中心",
        secondNav: "合作商列表",
      },
      status: null,
      disableds: true,
      bindMecVisible: false,
      leimuF: "",
      leimuZ: "",
      is_loading: false,
      type: "teach_paypal",
      region: [],
      listPic: [],
      listPageChild: [],
      recording: [], //精品课程
      pageSize: 10,
      currentPage: 1,
      total: 0,

      dialogVisible: false,
      updateVisible: false,
      mechanism_name: "",
      mechanism_telephone: "",
      form: {},
      temp: {},
      contact_telephone: null,
      categories_child: null,
      bindVisible: false,
      user_list: [],
      user_id: null,
      main_list: [],
      part_list: [],
    };
  },
  mounted() {
    this.GetList();
    this.GetListPageChild();
  },
  methods: {
    addVisible(event) {
      if (event == false) {
        if (this.leimuF != "") {
          this.disableds = false;
        } else {
          this.disableds = true;
          this.leimuZ = "";
        }
      }
    },
    openBind(row) {
      this.temp = row;
      this.bindVisible = true;
    },
    bindUser() {
      let url = "/user/mastermechanism/updateMechanism";
      this.$axios
        .post(url, {
          user_id: this.user_id,
          id: this.temp.id,
        })
        .then((res) => {
          if (res.data.code == 0) {
            this.$message({
              type: "success",
              message: "修改成功",
            });
          } else {
            this.$message({
              type: "error",
              message: re.data.message,
            });
          }
        });
    },
    // 类目查询
    getUserList(query) {
      let url = "/user/userInfo/queryByMessage";
      this.$axios
        .get(url, {
          params: {
            pageSize: 10,
            currentPage: 1,
            nick_name: typeof query == "string" ? query : undefined,
          },
        })
        .then((res) => {
          this.user_list = res.data.data.rows;
        });
    },
    upgradeToMain(part) {
      let a = this.part_list.findIndex((item) => {
        return item.id == part.id;
      });
      this.main_list.push(part);
      this.part_list.splice(a, 1);
    },
    openBindMec(row) {
      let url = "/user/mastermechanismSchool/queryManagerListPage";
      this.$axios
        .get(url, {
          params: {
            statsu: 2,
            mechanism_id: row.map.mechanism_name ? null : row.id,
            binding_mechanism_id: row.map.mechanism_name ? row.id : null,
          },
        })
        .then((res) => {
          if (row.map.mechanism_name) {
            this.main_list = [];
            this.temp = row;
            this.part_list = res.data.data.rows;
            this.bindMecVisible = true;
          } else {
            this.bindMainVisible = true;
          }
        });
    },
    getPartList(query) {
      let url = "/user/mastermechanismSchool/queryManagerListPage";
      this.$axios
        .get(url, {
          params: {
            statsu: 2,
            mechanism_id: temp.map.mechanism_name ? null : row.id,
            bind_mechanism_id: typeof query == "string" ? query : null,
          },
        })
        .then((res) => {
          if (res.data.data.rows.length) {
            this.part_list = [];
            this.part_list = res.data.data.rows.filter((item) => {
              this.main_list.forEach((mian) => {
                return item.id != mian.id;
              });
            });
            // this.bindMecVisible = true
            // console.log(this.main_list, this.part_list, 222)
          } else {
            // this.bindMainVisible = true
          }
        });
    },
    GetListPageChild() {
      let url =
        "/user/mechanismCategory/queryListPageChild?type=2&status=2&source=课程";
      this.$axios.get(url).then((res) => {
        this.listPageChild = res.data.data;
      });
    },

    fromTitle(row, column, cellValue) {
      if (cellValue === "") {
        return "暂无";
      } else {
        return cellValue;
      }
    },
    search() {
      this.GetList();
    }, //查询
    tuijian(e) {
      // console.log(e, "==========我是e========");
      let url = "/user/mastermechanism/update";
      let data = {
        id: e.id,
        is_recommend: e.is_recommend ? 1 : 0,
      };
      // console.log(e.is_recommend);
      if (data.is_recommend) {
        data.is_recommend = false;
      } else {
        data.is_recommend = true;
      }
      this.$axios.post(url, data).then((res) => {
        // console.log(res, "===========推荐========");
        this.GetList();
      });
    }, // 推荐
    filterChange(filterObj) {
      // console.log(filterObj);
    },
    // update(){
    //   let url = '/user/mastermechanism/update'
    //   this.$axios.post(url,{
    //     id:733,
    //     type:'teach_paypal'
    //   }).then(res=>{
    //     console.log(res);
    //   })
    // },
    GetList() {
      this.is_loading = true;
      let url = "/user/mastermechanism/queryByMessage";
      this.$axios
        .get(url, {
          params: {
            // type: 'offline_mechanism',
            type: "partners",
            status: this.status !== null ? this.status : null,
            // is_cooperation: true,
            pageSize: 10,
            currentPage: this.currentPage,
            mechanism_name: this.mechanism_name ? this.mechanism_name : null,
            contact_telephone: this.contact_telephone
              ? this.contact_telephone
              : null,
            contacts: this.contacts ? this.contacts : null,
            // categories: '体育运动',
            // source:'体育宝',
            categories_child: this.categories_child
              ? this.categories_child
              : null,
          },
        })
        .then((res) => {
          // console.log(res.data);
          this.recording = res.data.data.rows;
          this.total = res.data.data.total;
          this.is_loading = false;
        })
        .catch((rej) => {
          this.is_loading = false;
        });
    },
    openUpdate(row) {
      this.temp = row;
      this.updateVisible = true;
    },
    closeUpdate(v) {
      this.updateVisible = false;
      this.temp = {};
      if (v.is_change) {
        this.GetList();
      }
    },
    toDetails(row) {
      this.$router.push({ name: "mecDetails", query: { mecID: row.id } });
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.GetList();
    },
    frozen(row) {
      let url = "/user/mastermechanism/updateFreeze";
      this.$axios
        .post(url, {
          id: row.id,
          // id:733
        })
        .then((res) => {
          if (res.data.code == 0) {
            this.$message({ type: "warning", message: "冻结成功!" });
          } else {
            this.$message({ type: "danger", message: res.data.message });
          }
          this.GetList();
        });
    },
    unfrozen(row) {
      let url = "/user/mastermechanism/updateUnFreeze";
      this.$axios
        .post(url, {
          id: row.id,
          // id:733
        })
        .then((res) => {
          if (res.data.code == 0) {
            this.$message({ type: "success", message: "解冻成功!" });
          } else {
            this.$message({ type: "danger", message: res.data.message });
          }
          this.GetList();
        });
    },
    contacted(row) {
      let url = "/user/mastermechanism/update";
      this.$axios
        .post(url, {
          id: row.id,
          status: 7,
        })
        .then((res) => {
          if (res.data.code == 0) {
            this.$message({ type: "warning", message: "修改成功!" });
          } else {
            this.$message({ type: "danger", message: res.data.message });
          }
          this.GetList();
        });
    },
    handleClose(done) {
      this.$confirm("确认关闭?")
        .then((_) => {
          this.user_id = null;
          this.bindVisible = false;
          this.bindMecVisible = false;
          done();
        })
        .catch((_) => {});
    },
  },
};
</script>
<style>
.PERInformation-from > *:not(:last-child) {
  margin-right: 1rem;
}
</style>
